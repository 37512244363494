<template>
  <div class="mx-5 my-5">
    <h2>تجديد الاشتراك</h2>
    <v-row class="mt-2">
      <v-col class="py-0" :cols="3">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.package_id`),
          }"
        >
          <label>اختر باقتك</label>
          <v-autocomplete
            class="d-block my-2"
            name="package_id"
            data-vv-scope="addEditValidation"
            v-validate="'required'"
            :data-vv-as="$t('Package')"
            hide-details
            dense
            outlined
            v-model="formData.package_id"
            item-value="id"
            item-text="name"
            :items="packageList"
            clearable
            @change="getPackageAdvanatagesByPackageId"
          ></v-autocomplete>
        </div>

        <div
          class="help-block"
          v-if="errors.has(`addEditValidation.package_id`)"
        >
          {{ errors.first(`addEditValidation.package_id`) }}
        </div>
      </v-col>

      <v-col class="py-0" :cols="3">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.delivary_start_date`),
          }"
        >
          <label>تاريخ بداية التوصيل</label>
          <el-date-picker
            style="width: 100%"
            v-model="formData.delivary_start_date"
            type="date"
            v-validate="'required'"
            data-vv-scope="addEditValidation"
            :data-vv-as="$t('DelivaryStartDate')"
            class="d-block my-2"
            name="delivary_start_date"
            hide-details
            dense
            outlined
             :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>

        <div
          class="help-block"
          v-if="errors.has(`addEditValidation.delivary_start_date`)"
        >
          {{ errors.first(`addEditValidation.delivary_start_date`) }}
        </div>
      </v-col>
      <v-col :cols="6" class="mt-4">
        <v-row>
          <v-col md="12">
            <v-btn @click="payFromWallet" class="mx-2" color="success" dark>
              الدفع من المحفظة
            </v-btn>
            <v-btn
              @click="goToPayment()"
              class="mx-2"
              color="danger"
              style="color: #fff"
            >
              الدفع الإلكتروني
            </v-btn>
           
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <table id="renewelTable">
        <tr>
          <!-- <th style="width: 30px; text-align: center">اختر</th> -->
          <th style="width: 30px; text-align: center">#</th>
          <th>المميزات المتاحة</th>
          <th>العدد</th>
          <th>السعر</th>
          <th>المجموع</th>
        </tr>
        <template v-if="groupDetails">
  <tr v-for="(gd, gkey) in groupDetails" :key="gkey">
    <!-- <td>  <v-checkbox style="font-size:22px;" type="checkbox" v-model="gd.isChecked" /></td> -->
    <td style="width: 30px; text-align: center">{{ gkey + 1 }}</td>
    <td>{{ gd.group_name }}</td>
    <td style="width: 200px">
      <el-input-number
        class="d-block my-2"
        hide-details
        dense
        outlined
        v-model="gd.quantity"
        style="width: 180px"
        :min="0"
        :max="99"
  @change="checkQuantity(gd, $event, gd.total_quantity, gd.group_name, gd.switch)"
      ></el-input-number>
      <div v-if="gd.showError" class="error-message">
        {{ gd.errorMessage }}
      </div>
    </td>
    <td>{{ gd.price }}</td>
    <td>{{ gd.total }}</td>
  </tr>
</template>

        <tr>
          <td style="border: 0" colspan="3">
            <!-- <v-checkbox
              v-model="is_compensation_checked"
              style="display: inline-block"
            ></v-checkbox> -->
            <span>
              إضافة الأيام المتبقية لك من اشتراكاتك السابقة إلى الاشتراك الجديد
            </span>
          </td>
          <td style="border: 0; font-size: 20px" colspan="2">
            الإجمالي = <span>{{ parseFloat(total) + parseFloat(price_before_discount) - parseFloat(discount) }}</span
            ><span> ر.س </span>
            <span
              v-if="discount"
              style="position: relative; display: inline-block;right:30px;"
              class="mx-3"
            >
              <span
                style="
                  position: absolute;
                  top: 50%;
                  left: 0;
                  right: 0;
                  border-top: 1px solid red;
                  transform: rotate(-30deg);
                "
              ></span>
              <span style="position: relative; display: inline-block"
                >{{ parseFloat(price_before_discount) + parseFloat(total) }} ر.س</span
              >
            </span>
          </td>
        </tr>

        <tr>
          <td style="border: 0; padding: 15px" colspan="6">
            <p style="border: 1px solid #ccc; padding: 10px;">
             <span> عدد أيام الباقة: <span style="color:blue;">{{ parseFloat(number_of_days) }} يوم</span></span>
              <br><br>
              عدد الأيام المتبقية: <span style="color:red;">{{ parseFloat(remind_days) }} يوم</span>
            </p>
          </td>
          <td style="border: 0" colspan="2"></td>
          <td style="border: 0" colspan="1">
            <p
              style="
                text-align: center;
                font-weight: bold;
                max-width: 240px;
                border: 1px solid #dfeff1;
                padding: 10px;
                color: #000;
                background: #dfeff1;
              "
            >
              وفر {{ discount }} ر.س
            </p>
          </td>
        </tr>
      </table>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      remind_days: 0,
      packageList: [],
      formData: {
        package_id: null,
        delivary_start_date: null,
      },
      // is_compensation_checked: true,
      groupDetails: [],
      number_of_days: 0,
      discount: 0,
      price_before_discount: 0,
       pickerOptions: {
        disabledDate(time) {
          const currentDate = new Date();
          const twoDaysAfter = new Date(currentDate);
          twoDaysAfter.setDate(currentDate.getDate() + 1);
          // Disable dates that are after 2 days ago 
          return time < twoDaysAfter;
        },
      },
    };
  },
  computed: {
    total() {
      let all_tot = 0;
      this.groupDetails.forEach((item) => {
        all_tot = all_tot + item.total;
      });
      return all_tot;
    },
  },
  methods: {
      checkQuantity(gd, newValue, tot, groupName,swItems) {
        let quantity= 0; 
        swItems.forEach(it => {
  
         let item= this.groupDetails.find(x=>x.group_id ==  it.id);
         quantity= quantity+ item.quantity;
        });

        //         console.log(newValue)
        // console.log(gd.total_quantity)
        //   if(newValue < gd.total_quantity) {
        //      gd.total_quantity=  gd.total_quantity - 1;
        //      console.log(gd.total_quantity)
        //   }
          
    if ((newValue + quantity) > tot) {
      this.customNotifyError("خطأ", `يجب أن لا تتجاوز الحد الأقصى للعدد في ميزة ${groupName}`);
      setTimeout(() => {
        gd.quantity = gd.quantity - 1;
      }, 100);
    }
  },
    payFromWallet() {
    
      let su=  0;
      let tot= 0;
      let switchTotal= 0;

    this.groupDetails.forEach(x => {
      if(x.switch.length> 0 ) {
        x.switch.forEach(item => {
          let im= this.groupDetails.find(y=>y.group_id == item.id);
          // console.log(im)
          switchTotal= switchTotal + im.quantity
        });
      }

       su=  switchTotal + x.quantity;
       tot= x.total_quantity;
   

    })

    console.log('su', su);
    console.log('tot', tot);
      if(su> tot) { 
         this.customNotifyError("خطأ", `تحقق من عدد المميزات، هناك كميات زائدة`);
      }
    //// start_pay_from_wallet 
      else {

        // console.log('start ok ..')

          let toSend = {
        id: this.$route.params.id,
        renewal_date: moment(this.formData.delivary_start_date).format(
          "YYYY-MM-DD"
        ),
        package_id: this.formData.package_id,
        groups: this.groupDetails
          .filter((x) => x.quantity>0)
          .map((x) => {
            return {
              id: x.group_id,
              quantity: x.quantity,
            };
          }),
      };

      this.$store.dispatch(`customers/renewalSubscribe`, toSend).then((res) => {
        this.notifySuccess("تم تجديد الاشتراك بنجاح", res);
      });

      }
    


    },
    getTotal(item) {
      let total = 0;
      total = item.price * item.quantity;
      item.total = total;
      return total;
    },
    goToPayment() {

        let su=  0;
      let tot= 0;
      let switchTotal= 0;

    this.groupDetails.forEach(x => {
      if(x.switch.length> 0 ) {
        x.switch.forEach(item => {
          let im= this.groupDetails.find(y=>y.group_id == item.id);
          // console.log(im)
          switchTotal= switchTotal + im.quantity
        });
      }

       su=  switchTotal + x.quantity;
       tot= x.total_quantity;
   

    })

    console.log('su', su);
    console.log('tot', tot);
      if(su> tot) { 
         this.customNotifyError("خطأ", `تحقق من عدد المميزات، هناك كميات زائدة`);
      }
    //// start_pay_from_wallet 
      else {

        // console.log('start ok ..')

        localStorage.setItem("total_payment", this.total - (parseFloat(this.discount)));
        window.open(
          this.$router.resolve({
            name: "payment",
            params: { id: this.$route.params.id },
          }).href,
          "_blank"
        );
      }
    },
    getPackageAdvanatages() {
      this.$store
        .dispatch(`customers/getGroupDetails`, this.$route.params.id)
        .then((res) => {
          this.groupDetails = res.data.map((x) => {
            x.total = 0;
            x.isChecked = true;
            this.getTotal(x);
            return x;
          });
        });
    },
    getPackageAdvanatagesByPackageId() {
      if (this.formData.package_id) {
        let getObj = this.packageList.find(
          (x) => x.id == this.formData.package_id
        );

        if(getObj) {
          this.number_of_days = getObj.number_of_days;
          this.discount = getObj.discount;
          this.price_before_discount = getObj.price_before_discount;
        }

        let toSend = {
          package_id: this.formData.package_id,
          subscription_id: this.$route.params.id,
        }
        this.$store
          .dispatch(
            `customers/getGroupDetailsByPackageId`,
            toSend
          )
          .then((res) => {
            this.groupDetails = res.data.map((x) => {
              x.total = 0;
              // x.total_quantity= x.quantity;

              return x;
            });
          });
          
      }
    },
    getRemindDays() {
      this.$store
        .dispatch(`customers/findData`, this.$route.params.id)
        .then((res) => {
          if(res.data && res.data.subscribe && res.data.subscribe.remind_days)
          this.remind_days= res.data.subscribe.remind_days;
        });
    },
    getAllPackages() {
      this.$store
        .dispatch(`customers/getPackageList`, this.$route.params.id)
        .then((res) => {
          this.packageList = res.data.map(x=>{
            x.discount= x.price_before_discount - x.price;
            x.price_before_discount= x.price_before_discount;
            return x;
          })

              this.getPackageAdvanatagesByPackageId();

        });
    },
  },
  mounted() {
     let package_id_for_renew = localStorage.getItem("package_id_for_renew");
        this.formData.package_id = parseInt(package_id_for_renew) || 1;
        
    this.getAllPackages();
    this.getRemindDays();
    // this.getPackageAdvanatages();
  },
  watch: {
    "$route.params": {
      handler: function (newParams, oldParams) {
        let package_id_for_renew = localStorage.getItem("package_id_for_renew");
        this.formData.package_id = parseInt(package_id_for_renew) || 1;
        localStorage.removeItem("package_id_for_renew");
        this.getPackageAdvanatages();
        this.getAllPackages();
        this.getRemindDays();
        this.getPackageAdvanatagesByPackageId();
        let current_datetime = moment();
        let after_tomorrow_datetime = current_datetime.add(2, "day");
        this.formData.delivary_start_date = after_tomorrow_datetime;
      },
      immediate: true, // Call the handler immediately when the component is created
    },
  },
};
</script>